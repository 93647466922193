// Packages
import React from "react"
import { Paper, Grid2 as Grid, Typography, Box, useTheme, Button } from '@mui/material';

// Components
import Nav from '../components/Nav';
import Section from "../components/Section";
import AboutCard from "../components/AboutCard";
import FadeInUpAnimation from '../components/FadeInUp';
import Footer from "../components/Footer";

// Assets
import foundersPic from '../assets/images/about/founders-on-site.jpg';
import miniPcImg from '../assets/images/about/mini-pc-lincoin.jpg'
import rackImg from '../assets/images/miner-rack-sect-2.jpg'
import curvySection2 from '../assets/images/about/curvy-section-2.svg'

import pduPic from '../assets/images/about/about-3_pdu.jpg'
import containerPic from '../assets/images/about/about-3_container.jpg'
import trnsfrmrPic from '../assets/images/about/about-3_transformer.jpg'
import pwrLinesPic from '../assets/images/about/about-3_power-lines.jpg'
import pduText from '../assets/copy/about/about-3_pdu'
import containerText from '../assets/copy/about/about-3_container'
import trnsfrmrText from '../assets/copy/about/about-3_container'
import pwrLinesText from '../assets/copy/about/about-3_container'

import JayZapataPfp from '../assets/images/about/JayZapata.jpg'
import ShykeLowersPfp from '../assets/images/about/ShykeLowers.jpg'
import OmarAlatorrePfp from '../assets/images/about/OmarAlatorre.jpg'
import AlexYoungPfp from '../assets/images/about/AlexYoung.jpg'
import JuanGonzalezPfp from '../assets/images/about/JuanGonzalez.jpg'
import TaylorFewellPfp from '../assets/images/about/TaylorFewell.jpg'
import JoelAdamsPfp from '../assets/images/about/JoelAdams.jpg'

interface AProps {
  activeRoute: string,
  isMobile: boolean,
  isTablet: boolean
}

export default function About({activeRoute, isMobile, isTablet}: AProps): React.JSX.Element {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Paper sx={{position: 'relative', flexGrow: 1, overflowX: 'hidden', borderRadius:'0'}}>
        <Section id='about-1' elevation={6} height={isMobile ? 30 : 90} sx={{ overflow: 'hidden' }}>
          <Nav activeRoute={activeRoute} isMobile={isMobile}/>
          <Box id='about-img-container' sx={{width: '100%', height: '100%', position: 'relative'}}>
            <Box id='about-img' sx={{width: '100%', height: '100%', position: 'absolute', top: '-10%', left: '-5%'}}>
              <img
                src={foundersPic}
                alt="Satokie Founders"
                style={{
                  objectFit: 'cover',
                  height: '110%',
                  width: '105%',
                  objectPosition: 'top'
                }} />
            </Box>
            <Box id='about-overlay' sx={{ width: '100%', height: '100%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, .3)' }}>
            </Box>
          </Box>
        </Section>
        <Section id='about-2' elevation={0} height={isMobile ? 55 : isTablet ? 80 : 158} sx={{ position: 'relative', overflow: 'hidden', backgroundColor: '#faf7f0', padding: '1cqw 0 2cqw 0' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', borderRadius: '0', position: 'absolute' }}>
            <Grid container sx={{width: '90%'}} rowSpacing={6}>
              <Grid size={6}>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
                  <Typography variant='h3' sx={{ fontWeight:'600', fontSize: '5cqw', marginBottom: '2cqw', whiteSpace: 'nowrap'}} color='#062e60'>Who We Are</Typography>
                  <Typography variant='h6' sx={{ textWrap: 'wrap', fontSize: 'calc(.7vh + .8vw)' }} color='#062e60'>
                    <p>Satokie Mining was founded in 2023 by Jay Zapata, Shyke Lowers, and Omar Alatorre with a mission to revolutionize Bitcoin mining through cutting-edge technology and sustainable practices.</p>
                    <p>We are dedicated to optimizing our operations to maximize efficiency and profitability while maintaining a focus on environmental responsibility.</p>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <FadeInUpAnimation>
                    <img
                    src={miniPcImg}
                    alt="Miner Dashboard Screen" 
                    style={{
                      width: '40cqw',
                      height: 'auto',
                      aspectRatio: '1/1',
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                  />
                  </FadeInUpAnimation>
                </Box>
              </Grid>
              <Grid size={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', height: '100%' }}>
                  <FadeInUpAnimation>
                    <img
                    src={rackImg}
                    alt="Miners on a Rack" 
                    style={{
                      width: isMobile ? '40cqw' : '30cqw',
                      height: 'auto',
                      aspectRatio: '1/1',
                      objectFit: 'cover'
                    }}
                  />
                  </FadeInUpAnimation>
                </Box>
              </Grid>
              <Grid size={6} sx={{display: 'grid', alignItems: 'center'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
                  <Typography variant='h3' sx={{ fontWeight:'600', fontSize: '5cqw', marginBottom: '2cqw', whiteSpace: 'nowrap'}} color='#062e60'>Our Operations</Typography>
                  <Typography variant='h6' sx={{ textWrap: 'wrap', fontSize: 'calc(.7vh + .8vw)' }} color='#062e60'>
                    <p>At our Kyle site, we operate six state-of-the-art data containers and three high-capacity transformers, currently delivering 5 megawatts of power, with plans to expand to 15 megawatts.</p>
                    <p>Each container houses 240 ASICs (Application-Specific Integrated Circuits) designed for efficient Bitcoin mining.</p>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Section id='about-3' elevation={0} height={isMobile ? 80 : isTablet ? 100 : 210} sx={{ position: 'relative', overflow: 'hidden', backgroundColor: theme.palette.primary.main, border: 'none' }}>
          <Box id='about-3-background' sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                zIndex: 1,
                position: 'absolute',
                width: '150%',
                height: '100%',
                bottom: '0',
                right: '-10%',
                objectFit: 'cover'
              }}
              src={curvySection2} alt="svg curvy background image" />
          </Box>
          <Box sx={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Grid container rowSpacing={2} sx={{ display: 'grid', justifyContent: 'center', padding: '5cqw', zIndex: 2, position: 'relative'}}>
              <Grid>
                <Typography variant='h3' sx={{ fontWeight:'600', fontSize: 'calc(3vh + 2vw)', marginBottom: '2cqw', textAlign: 'center', textShadow: '0em .1em .2em rgba(0,0,0,.9) !important' }} color={theme.palette.secondary.main}>What Makes Us Special</Typography>
              </Grid>
              <Grid>
                <AboutCard imgSrc={pduPic} cardText={pduText()} imgAlt={'Smart PDU'}/>
              </Grid>
              <Grid>
                <AboutCard imgSrc={containerPic} cardText={containerText()} imgAlt={'Kyle Container'}/>
              </Grid>
              <Grid>
                <AboutCard imgSrc={trnsfrmrPic} cardText={trnsfrmrText()} imgAlt={'JCI Transformer'}/>
              </Grid>
              <Grid>
                <AboutCard imgSrc={pwrLinesPic} cardText={pwrLinesText()} imgAlt={'Power Lines'}/>
              </Grid>
              <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '3cqw' }}>
                <FadeInUpAnimation>
                  <Button variant='contained' sx={{minWidth: '20cqw', backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main}}>
                  LEARN MORE
                </Button>
                </FadeInUpAnimation>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Section id='about-4' elevation={0} height={isMobile ? 80 : isTablet ? 100 : 150} sx={{ position: 'relative', overflow: 'hidden' }}>
          <Box id='about-3-background' sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                zIndex: 1,
                position: 'absolute',
                width: '150%',
                height: '100%',
                objectFit: 'cover',
                transform: 'scaleX(-1)'
              }}
              src={curvySection2} alt="svg curvy background image" />
          </Box>
          <Box sx={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Grid container rowSpacing={5} columnSpacing={3} sx={{ justifyContent: 'center', padding: '5cqw', zIndex: 2, textAlign: 'center', position: 'relative'}} color='#062e60'>
              <Grid size={12} sx={{ marginBottom: '2cqw'}}>
                <Typography variant='h3' sx={{ fontWeight: '600', fontSize: 'calc(3vh + 2vw)', zIndex: 2 }}>MEET THE TEAM</Typography>
                <Typography variant="h6" sx={{fontSize: 'calc(.8vh + .8vw)'}}>Behind Satokie Mining</Typography>
              </Grid>
              <Grid size={4}>
                <FadeInUpAnimation>
                  <img src={JayZapataPfp} alt="Jay Zapata" width={"100%"}/>
                  <Typography variant="h4" sx={{fontSize: '2cqw'}}>Jay Zapata</Typography>
                  <Typography variant="h6" sx={{fontSize: '1.5cqw', fontWeight: '400'}}>Chief Executive Officer</Typography>
                </FadeInUpAnimation>
              </Grid>
              <Grid size={4}>
                <FadeInUpAnimation>
                  <img src={ShykeLowersPfp} alt="Shyke Lowers" width={"100%"}/>
                  <Typography variant="h4" sx={{fontSize: '2cqw'}}>Shyke Lowers</Typography>
                  <Typography variant="h6" sx={{fontSize: '1.5cqw', fontWeight: '400'}}>Chief Operating Officer</Typography>
                </FadeInUpAnimation>
              </Grid>
              <Grid size={4}>
                  <FadeInUpAnimation>
                    <img src={OmarAlatorrePfp} alt="Omar Alatorre" width={"100%"}/>
                    <Typography variant="h4" sx={{fontSize: '2cqw'}}>Omar Alatorre</Typography>
                    <Typography variant="h6" sx={{fontSize: '1.5cqw', fontWeight: '400'}}>Director of Site Operations</Typography>
                </FadeInUpAnimation>
              </Grid>
              <Grid size={3}>
                <FadeInUpAnimation>
                  <img src={AlexYoungPfp} alt="Alex Young" width={"100%"}/>
                  <Typography variant="h4" sx={{fontSize: '2cqw'}}>Alex Young</Typography>
                  <Typography variant="h6" sx={{fontSize: '1.5cqw', fontWeight: '400'}}>Technician</Typography>
                </FadeInUpAnimation>
              </Grid>
              <Grid size={3}>
                <FadeInUpAnimation>
                  <img src={JuanGonzalezPfp} alt="Juan Gonzalez" width={"100%"}/>
                  <Typography variant="h4" sx={{fontSize: '2cqw'}}>Juan Gonzalez</Typography>
                  <Typography variant="h6" sx={{fontSize: '1.5cqw', fontWeight: '400'}}>Technician</Typography>
                </FadeInUpAnimation>
              </Grid>
              <Grid size={3}>
                <FadeInUpAnimation>
                  <img src={TaylorFewellPfp} alt="Taylor Fewell" width={"100%"}/>
                  <Typography variant="h4" sx={{fontSize: '2cqw'}}>Taylor Fewell</Typography>
                  <Typography variant="h6" sx={{fontSize: '1.5cqw', fontWeight: '400'}}>Operational Manager</Typography>
                </FadeInUpAnimation>
              </Grid>
              <Grid size={3}>
                <FadeInUpAnimation>
                  <img src={JoelAdamsPfp} alt="Joel Adams" width={"100%"}/>
                  <Typography variant="h4" sx={{fontSize: '2cqw'}}>Joel Adams</Typography>
                  <Typography variant="h6" sx={{fontSize: '1.5cqw', fontWeight: '400'}}>Operations Administrator</Typography>
                </FadeInUpAnimation>
              </Grid>
              <Grid size={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '3cqw' }}>
                <FadeInUpAnimation>
                  <Button variant='contained' sx={{minWidth: '20cqw',color: theme.palette.secondary.main, backgroundColor: theme.palette.primary.main}}>
                    LEARN MORE
                  </Button>
                </FadeInUpAnimation>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Footer isMobile={isMobile}></Footer>
      </Paper>
    </React.Fragment>
  )
}