import React from 'react';
import {Box, Grid2 as Grid, Typography, useTheme} from '@mui/material';
import SlideFromLeftAnimation from './SlideFromLeft';
import num1 from '../assets/images/faq/num1.svg';
import num2 from '../assets/images/faq/num2.svg';
import num3 from '../assets/images/faq/num3.svg';
import num4 from '../assets/images/faq/num4.svg';
import num5 from '../assets/images/faq/num5.svg';
import FadeInAnimation from './FadeIn';

interface SIProps {
  title?: string,
  content?: string,
  titleFontSize?: string,
  contentFontSize?: string, 
  stepNumber: number, //Up to 5 - don't have images beyond that
  titleDelay?: number | 0,
  contentDelay?: number | 0
}


export default function StepItem({title, content, titleFontSize, contentFontSize, stepNumber, titleDelay, contentDelay} : SIProps): React.JSX.Element{

  const theme = useTheme();

  const imgMap: Map<number, string> = new Map([
    [1, num1],
    [2, num2],
    [3, num3],
    [4, num4],
    [5, num5]
  ])

  
  
  return (
    <React.Fragment>
      <Box id='step-item-parent-container' sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '3cqw', overflow: 'hidden'}}>
        <Box id='title-container' sx={{width: '100%', height: '35%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderTop: `solid .1cqw ${theme.palette.primary.main}`, borderBottom: `solid .1cqw ${theme.palette.primary.main}`}}>
          <Box sx={{height: '80%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
            <img src={imgMap.get(stepNumber)} alt={`This is a number image indication step ${stepNumber}`} style={{ width: '10%', height: '60%', margin:'1cqw'}}/>
            <FadeInAnimation delay={titleDelay}>
              <Typography sx={{fontSize: titleFontSize, fontWeight: 800, color: 'black', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{title}</Typography>
            </FadeInAnimation>
          </Box>
        </Box>
        <Box id='content-container' sx={{width: '100%', height: '60%', marginTop: '1.5cqw', zIndex: 1}}>
          <SlideFromLeftAnimation delay={contentDelay}>
            <Typography sx={{zIndex: 0, fontSize: contentFontSize}}>{content}</Typography>
          </SlideFromLeftAnimation>
        </Box>
      </Box>
    </React.Fragment>
  )
}

