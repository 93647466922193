import React, { ReactNode } from "react";
import {motion, MotionStyle} from 'framer-motion';

interface SlideFromLeftProps {
  children?: ReactNode,
  delay?: number | 0,
  sx?: MotionStyle
}


export default function SlideFromLeftAnimation({children, delay, sx}: SlideFromLeftProps): React.JSX.Element {

  return(
    <motion.div
      initial={{x: '-110%'}}
      whileInView={{x: 0}}
      transition={{duration: .5, delay: delay}}
      viewport={{once: true}}
      style={sx}
    >{children}</motion.div>
  )

}