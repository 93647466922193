import React from 'react';
import Section from '../components/Section';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import { Box, Paper, useTheme, Typography, Grid2 as Grid } from '@mui/material';
import warehouseImg from '../assets/images/warehouse.webp';
import terralinesSvg from '../assets/images/midstream/terralines.svg';
import racksCompImg from '../assets/images/racks-comp.webp';
import FadeInUpAnimation from '../components/FadeInUp';
import { TicketItem } from '../components/VerticalTicketItem';
import teamworkImg from '../assets/images/teamwork.webp';
import exchangeSvg from '../assets/images/midstream/exchange.svg';
import alwaysUpSvg from '../assets/images/midstream/always-up-svg.svg';
import archerySvg from '../assets/images/midstream/archery-svg.svg';
import FadeInAnimation from '../components/FadeIn';
import SlideFromLeftAnimation from '../components/SlideFromLeft';

interface MProps {
  activeRoute: string,
  isMobile: boolean
}

export default function Midstream({activeRoute, isMobile}:MProps): React.JSX.Element {
  
  const theme = useTheme();
  
  
  return (
    <React.Fragment>
    <Paper elevation={0} sx={{position: 'relative', backgroundColor: '#000'}}>
      <Section id='sm-1' height={isMobile ? 30 : 90} sx={{position: 'relative'}}>
        <Nav activeRoute={activeRoute} isMobile={isMobile}></Nav>
        <Box sx={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img style={{zIndex: 0, objectFit: 'cover', width: '100%', height: '100%'}}src={warehouseImg} alt="Computer warehouse image" />
        </Box>
        <Box sx={{width: '100%', height: '100%', position: 'absolute', zIndex: 1, backgroundColor: 'black', opacity: .35, boxShadow: '0px 16px 16px rgba(0,0,0, .9)'}}></Box>
      </Section>
      <Section id='sm-2' height={isMobile ? 40 : 120} sx={{position: 'relative', backgroundColor: '#f8f5ef'}}>
        <Box id='sm-2-background-container' sx={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img style={{zIndex: 0, objectFit: 'cover', width: '100%', height: '100%'}}src={terralinesSvg} alt="Computer warehouse image" />
        </Box>
        <Box id='sm-2-foreground-container'sx={{position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5cqw'}}>
          <Box id='sm-2-left-image' sx={{ width: '50%', height: '100%', zIndex: 0}}>
            <FadeInAnimation sx={{width: '100%', height: '100%'}}>
              <img src={racksCompImg} alt="Computer Racks Image" style={{objectFit: 'cover', width: '100%', height: '100%'}} />
            </FadeInAnimation>
          </Box>
          <Box id='sm-2-right-copy-container' sx={{ width: '50%', height: '100%', padding: '0 5cqw 0 5cqw', zIndex: 1}}>
            <FadeInAnimation delay={.4}>
              <Box id='sm-2-title' sx={{ paddingBottom: '1cqw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '25%'}}>
                <Typography color={theme.palette.primary.main}  sx={{ fontSize: '3cqw', fontWeight: 'bold'}}>Empower Your ​Operations With <br></br>A ​Digital Midstream</Typography>
              </Box>
            </FadeInAnimation>
            <hr style={{border: 'none', margin: '.5em 0 .5em 0', height: '.1rem', width: '80%', backgroundColor: theme.palette.primary.main}}/>
            <Box id='sm-2-body' sx={{width: '100%', height: '75%', marginTop: '1cqw', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
              <FadeInAnimation sx={{ height: '85%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', zIndex: 0}}>
                <Typography sx={{fontSize: '1.4cqw'}}>Our digital midstream service allows producers to ​monetize their hydrocarbons directly on the well site. ​Unlike traditional midstream options, we empower ​producers by:</Typography>
                <Typography sx={{fontSize: '1.4cqw'}}><b>Owning The Infrastructure:</b> Assets that are purchased ​for the service are 100% owned by the producer.</Typography>
                <Typography sx={{fontSize: '1.4cqw'}}><b>Scalability & Mobility:</b> Our containerized-skid solutions ​come in different sizes to accommodate different ​terrain and gas volumes. This makes it super easy to ​transport the asset to different well sites.</Typography>
                <Typography sx={{fontSize: '1.4cqw'}}><b>Cost Efficiency:</b> Gas Tap Fees are a sunk cost. That ​capital can now be turned into producing Assets.</Typography>
              </FadeInAnimation>
            </Box>
          </Box>
        </Box>

      </Section>
      <Section id='sm-3' height={isMobile ? 30 : 120} sx={{position: 'relative', backgroundColor: '#f8f5ef'}}>
          <Box id='sm-3-background' sx={{position: 'absolute', backgroundColor: '#f8f5ef', zIndex: 0, width: '100%', height: '100%'}}>
            <Box>
              <img src={teamworkImg} alt='background container image' style={{zIndex: 0, width: '100%', height: '100%', objectFit: 'cover', position: 'absolute'}}></img>
              <Box sx={{width: '100%', height: '100%', position: 'absolute', zIndex: 1, backgroundColor: 'white', opacity: .75, boxShadow: '0px 16px 16px rgba(0,0,0, .9)'}}></Box>
            </Box>
          </Box>
          <Box id='grid-box'  sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', zIndex: 1, padding: '2cqw'}}>
            <Box sx={{width: '100%', height: '20%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}>
              <FadeInAnimation delay={.2}>
                <Typography sx={{fontSize: '3.5cqw', fontWeight: 'bold', color: theme.palette.primary.main, lineHeight: '1'}}>BREAK FREE</Typography>
              </FadeInAnimation>
              <FadeInAnimation delay={0.4}>
                <Typography sx={{fontSize: '2.0cqw', fontWeight: 200, color: theme.palette.primary.main}}>FROM EXPENSIVE PIPELINE DEPENDENCE</Typography>
              </FadeInAnimation>
            </Box>
            <FadeInUpAnimation sx={{width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Grid  sx={{ width: '90%', height: '80%'}} id='ticket-container' wrap='nowrap' container spacing={3}>
                <Grid id='ticket-1' size={4} position='relative'>
                  <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Box id='ticket-image-1' sx={{backgroundColor: 'rgba(0,0,0,0)', width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
                      <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                    </Box>
                    <Box id='ticket-content-1' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <img style={{objectFit: 'contain', width:'6cqw', height:'6cqw', aspectRatio: '1/1', marginBottom: '2cqw', }} src={exchangeSvg} alt="An icon indicating exchanging goods" />
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography textAlign='center' color={theme.palette.secondary.main} sx={{fontSize: '1.5cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>ACCESS NEW MARKETS</Typography>
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography sx={{fontSize: '1cqw'}} textAlign='center' color={theme.palette.secondary.main}>Having access to new markets ​allows for a “Floor Price” to be set ​and give optionality to Producers & ​Investors.</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid id='ticket-2' size={4} position='relative'>
                  <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Box id='ticket-image-2' sx={{width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
                      <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                    </Box>
                    <Box id='ticket-content-2' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <img style={{objectFit: 'contain', width:'6cqw', height:'6cqw', aspectRatio: '1/1', marginBottom: '2cqw'}} src={alwaysUpSvg} alt="an image indicating a chart of growth over time" />
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography textAlign='center' color={theme.palette.secondary.main} sx={{fontSize: '1.5cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>OPERATIONAL CONTROL</Typography>
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography sx={{fontSize: '1cqw'}} textAlign='center' color={theme.palette.secondary.main}>Take charge of your transportation ​logistics, reducing bottlenecks & ​improving delivery times.</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid id='ticket-3' size={4} position='relative'>
                  <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Box id='ticket-image-3' sx={{width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
                      <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                    </Box>
                    <Box id='ticket-content-3' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <img style={{objectFit: 'contain', width:'6cqw', height:'6cqw', aspectRatio: '1/1', marginBottom: '2cqw'}} src={archerySvg} alt="An image indicating an arrow striking the center of the target" />
                      </Box>
                      <Box sx={{width: '90%', height: 'auto'}}>
                        <Typography 
                          textAlign='center' 
                          color={theme.palette.secondary.main}
                          sx={{fontSize: '1.5cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>INNOVATE WITH CONFIDENCE</Typography>
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography sx={{fontSize: '1cqw'}} textAlign='center' color={theme.palette.secondary.main}>Stay ahead of industry trends with a ​partner committed to bringing the ​latest in digital innovation to your ​operations.</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </FadeInUpAnimation>
          </Box>
      </Section>
      <Footer isMobile={isMobile}></Footer>
    </Paper>

    </React.Fragment>
  )
}