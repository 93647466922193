// Packages
import React from "react"
import { Paper, Grid2 as Grid, Typography, Box, useTheme } from '@mui/material';

// Components
import Nav from '../components/Nav';
import Section from "../components/Section";
import FadeInUpAnimation from '../components/FadeInUp';
import { TicketItem } from "../components/VerticalTicketItem";
import Footer from "../components/Footer";
import StepItem from '../components/StepItem'

// Assets
import containerPic from '../assets/images/container.webp';
import teamworkImg from '../assets/images/teamwork.webp';
import bitcoinSvg from '../assets/images/bitcoinLogo.svg';

interface FProps {
  activeRoute: string,
  isMobile: boolean
}

export default function Faq({activeRoute, isMobile}: FProps): React.JSX.Element {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Paper sx={{position: 'relative', overflowX: 'hidden', borderRadius:'0'}}>
        <Section id='faq-1' height={isMobile ? 30 : 80} sx={{ overflow: 'hidden', boxShadow: '0px 16px 16px rgba(0,0,0, .9)', zIndex: 0 }}>
          <Nav activeRoute={activeRoute} isMobile={isMobile}/>
          <Box id='faq-img-container' sx={{width: '100%', height: '100%', position: 'relative'}}>
            <Box id='faq-img' sx={{width: '100%', height: '100%', position: 'absolute'}}>
              <img
                src={containerPic}
                alt="Kyle Container"
                style={{
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%',
                  zIndex: 0
                }} />
            </Box>
            <Box id='faq-overlay' sx={{width: '100%', height: '100%', position: 'absolute', zIndex: 1, backgroundColor: 'black', opacity: .35, boxShadow: '0px 16px 16px rgba(0,0,0, .9)'}}></Box>
          </Box>
        </Section>
        <Section id='faq-2' elevation={0} height={isMobile ? 30 : 120} sx={{position: 'relative', backgroundColor: '#f8f5ef', zIndex: 0}}>
          <Box id='faq-2-background' sx={{position: 'absolute', backgroundColor: '#f8f5ef', zIndex: 0, width: '100%', height: '100%'}}>
            <Box>
              <img src={teamworkImg} alt='background container image' style={{zIndex: 0, width: '100%', height: '100%', objectFit: 'cover', position: 'absolute'}}></img>
              <Box sx={{width: '100%', height: '100%', position: 'absolute', zIndex: 1, backgroundColor: 'white', opacity: .75, boxShadow: '0px 8px 8px rgba(0,0,0, .5)'}}></Box>
            </Box>
          </Box>
          <Box id='grid-box'  sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', zIndex: 1, padding: '2cqw'}}>
            <FadeInUpAnimation sx={{width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Box sx={{width: '100%', height: '80%%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Grid  sx={{ width: '80%', height: '90%'}} id='ticket-container' wrap='nowrap' container spacing={3}>
                  <Grid id='ticket-1' size={6} position='relative'>
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <Box id='ticket-image-1' sx={{backgroundColor: 'rgba(0,0,0,0)', width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
                        <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                      </Box>
                      <Box id='ticket-content-1' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                        <Box display='flex' justifyContent='center' alignItems='center'>
                          <img style={{objectFit: 'contain', width:'6cqw', height:'6cqw', aspectRatio: '1/1', marginBottom: '2cqw', }} src={bitcoinSvg} alt="An icon indicating bitcoin currency" />
                        </Box>
                        <Box sx={{width: '80%', height: 'auto'}}>
                          <Typography textAlign='center' color={theme.palette.secondary.main} sx={{fontSize: '1.75cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>BITCOIN MINING</Typography>
                        </Box>
                        <Box sx={{width: '80%', height: 'auto'}}>
                          <Typography sx={{fontSize: '1.25cqw'}} textAlign='center' color={theme.palette.secondary.main}>Bitcoin mining is the process of ​verifying and adding new ​transactions to the Bitcoin ​blockchain. Miners use powerful ​computers to solve complex ​mathematical problems, which ​confirms transactions and secures ​the network. In return for their ​efforts, miners are rewarded with ​newly created Bitcoins and ​transaction fees. This process ​ensures the integrity of the Bitcoin ​network, prevents double-​spending, and gradually releases ​new Bitcoins into circulation.</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid id='ticket-2' size={6} position='relative'>
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <Box id='ticket-image-2' sx={{width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
                        <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                      </Box>
                      <Box id='ticket-content-2' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                        <Box display='flex' justifyContent='center' alignItems='center'>
                          <img style={{objectFit: 'contain', width:'6cqw', height:'6cqw', aspectRatio: '1/1', marginBottom: '2cqw'}} src={bitcoinSvg} alt="an icon indicating bitcoin currency" />
                        </Box>
                        <Box sx={{width: '80%', height: 'auto'}}>
                          <Typography textAlign='center' color={theme.palette.secondary.main} sx={{fontSize: '1.75cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>INVESTMENT</Typography>
                        </Box>
                        <Box sx={{width: '80%', height: 'auto'}}>
                          <Typography sx={{fontSize: '1.25cqw'}} textAlign='center' color={theme.palette.secondary.main}>Bitcoin mining can be a sound ​investment due to its potential for ​high returns. As the value of Bitcoin ​increases, the rewards for mining ​also rise. Additionally, mining ​provides a consistent stream of ​Bitcoin through block rewards and ​transaction fees, offering a way to ​accumulate Bitcoin over time. The ​decentralized nature of Bitcoin ​ensures that it remains resilient to ​external economic factors, ​potentially providing stability in ​uncertain times​. <br/><br/></Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </FadeInUpAnimation>
          </Box>
        </Section>
        <Section id='faq-3' elevation={0} height={isMobile ? 35 : 120}>
          <Box id='faq-3-parent-container' sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '5cqw'}}>
            <Box id='faq-3-title-container' sx={{width: '90%', height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
              <Typography sx={{fontSize: '4cqw', textAlign: 'left', fontWeight: 900}}>What Is The Mining Process? </Typography>
            </Box>
            <Box id='faq-3-grid-container' sx={{width: '100%', height: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Grid container spacing={0} sx={{width: '100%', height: '100%'}}>
                <Grid size={4}>
                  <StepItem 
                    stepNumber={1} 
                    title='Setup mining hardware' titleFontSize='2cqw'
                    content='Purchase powerful ASIC hardware, ​considering electricity costs and ​internet connection quality. Used ASICs ​can be a budget-friendly option.' contentFontSize='1.5cqw'
                    titleDelay={0}
                    contentDelay={0}
                    ></StepItem>
                </Grid>
                <Grid size={4}>
                  <StepItem 
                    stepNumber={2} 
                    title='Create a Bitcoin wallet' titleFontSize='2cqw'
                    content='Set up a separate Bitcoin wallet for ​mining, ideally a hard wallet for added ​security.' contentFontSize='1.5cqw'
                    titleDelay={.2}
                    contentDelay={.2}
                    ></StepItem>
                </Grid>
                <Grid size={4}>
                  <StepItem 
                    stepNumber={3} 
                    title='Configure your equipment' titleFontSize='2cqw'
                    content='Install and configure mining software, ​like NiceHash Miner. A GUI interface ​can simplify the process.' contentFontSize='1.5cqw'
                    titleDelay={.4}
                    contentDelay={.4}
                    ></StepItem>
                </Grid>
                <Grid size={4}>
                  <StepItem 
                    stepNumber={4} 
                    title='Join a mining pool' titleFontSize='2cqw'
                    content='Join a group of miners to combine ​computing power, ensuring more ​consistent rewards.' contentFontSize='1.5cqw'
                    titleDelay={.6}
                    contentDelay={.6}
                    ></StepItem>
                </Grid>
                <Grid size={4}>
                  <StepItem 
                    stepNumber={5} 
                    title='Start mining' titleFontSize='2cqw'
                    content="Download the blockchain and start ​mining. Regularly check your rig's ​performance. The mining process will ​run 24/7." contentFontSize='1.5cqw'
                    titleDelay={.8}
                    contentDelay={.8}
                    ></StepItem>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Section>
        <Footer isMobile={isMobile}></Footer>
      </Paper>
    </React.Fragment>
  )
}