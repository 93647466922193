//*Packages
import React from 'react';
import {Box, Grid2 as Grid, Typography, Paper, useTheme, Stack, Card, Button} from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import FactoryIcon from '@mui/icons-material/Factory';
import ConstructionIcon from '@mui/icons-material/Construction';
import BuildIcon from '@mui/icons-material/Build';

//*Components
import '../styles/global.css'
import Nav from '../components/Nav';
import VideoItem from '../components/VideoItem';
import Section from '../components/Section';
import VerticalTicketItem from '../components/VerticalTicketItem';
import FadeInUpAnimation from '../components/FadeInUp';
import FadeInAnimation from '../components/FadeIn';
import Footer from '../components/Footer';
//*Assets
import videoUrl from '../assets/videos/tour-compressed.mp4';
import curvySection from '../assets/images/curvy-section.svg';
import rackImg from '../assets/images/miner-rack-sect-2.jpg';
import siteImg from '../assets/images/site-sect2-cropped-1.webp';
import terralinesSvg from '../assets/images/terralines.svg';
import teamImg from '../assets/images/team-on-site.jpg';
import racksImg from '../assets/images/racks-comp.jpg';
import siteRowsImg from '../assets/images/site-rows.jpg'
import teamInspectionImg from '../assets/images/teamwork-inspection.webp';
import jayHeadshot from '../assets/images/Jay-headshot.webp';
import shykeHeadshot from '../assets/images/shyke-headshot.webp';
import omarHeadshot from '../assets/images/omar-headshot.webp';


interface HProps {
  activeRoute: string,
  isMobile: boolean
}

export default function Home({activeRoute, isMobile}: HProps): React.JSX.Element {
  const theme = useTheme();

  const isTablet = window.innerWidth < 685 && window.innerWidth > 450;

  return (
    <React.Fragment>
    {isMobile ? 
    //Mobile
      <Paper  elevation={0} sx={{position: 'relative', flexGrow: 1, overflowX: 'hidden', borderRadius: '0'}}>
        <Nav activeRoute={activeRoute} isMobile={isMobile}/>
        <Section id='hero' elevation={0} height={80}>
          <Grid container direction='column' spacing={0} sx={{justifyContent: 'center', alignItems:'center', width: '100%', height: '100%'}}>
            <Grid sx={{height: '70%'}}>
              <Box sx={{
                width: '100%', 
                height: '100%', 
                overflow: 'hidden', 
                boxShadow: '0px 8px 8px rgba(0,0,0, .5)',
                zIndex: 2}}>
                <VideoItem sx={{width: '100%', height: '100%', objectFit: 'cover'}} videoUrl={videoUrl} isMobile={isMobile} typeString={'video/mp4'}/>
              </Box>
            </Grid>
            <Grid sx={{height: '20%'}}>
              <Typography variant='h3' sx={{fontSize: 'calc(2vh + 2vw)', textWrap: 'wrap', fontWeight: 'bold', margin: '4cqw'}}>We build, own & operate data centers to support the bitcoin network</Typography>
            </Grid>
            <Grid sx={{height: '10%'}}>
              <Button
                variant='contained'
                color='secondary'
                sx={{marginBottom: '2cqw'}}
                onClick={() => document.querySelector('#footer-contact')?.scrollIntoView({ behavior: 'smooth' })}>
                Partner with us now
              </Button>
            </Grid>
          </Grid>
        </Section>
        <Section id='services' elevation={0} height={180} sx={{backgroundColor: theme.palette.secondary.main, position: 'relative', zIndex:'0', paddingBottom: '2cqw', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '80%', height: '90%', position: 'relative'}}>
            <FadeInAnimation delay= {0.4} sx={{width: '100%', height: '100%'}}>
              <VerticalTicketItem 
                theme={theme}
                icon={FactoryIcon}
                contentTitle='Site Operations'
                content='TBD'
                titleFontSize='4cqw'
                contentFontSize='2cqw'
                iconSx={{width: '10cqw', height: 'auto'}}
              ></VerticalTicketItem>
            </FadeInAnimation>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '80%', height: '90%', position: 'relative'}}>
            <FadeInAnimation delay={0.2}>
              <VerticalTicketItem
                theme={theme}
                icon={ConstructionIcon}
                contentTitle="Site Acquisition & Development"
                content='TBD'
                titleFontSize='4cqw'
                contentFontSize='2cqw'
                iconSx={{width: '10cqw', height: 'auto'}}
              ></VerticalTicketItem>
            </FadeInAnimation>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '80%', height: '90%', position: 'relative'}}>
            <FadeInAnimation delay={0.2}>
              <VerticalTicketItem
                theme={theme}
                icon={BuildIcon}
                contentTitle='ASIC Repair'
                content='TBD'
                titleFontSize='4cqw'
                contentFontSize='2cqw'
                iconSx={{width: '10cqw', height: 'auto'}}
              ></VerticalTicketItem>
            </FadeInAnimation>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '80%', height: '90%', position: 'relative'}}>
            <FadeInAnimation delay={0.2}>
              <VerticalTicketItem
                theme={theme}
                icon={ElectricBoltIcon}
                contentTitle='Energy Consultation'
                content='TBD'
                titleFontSize='4cqw'
                contentFontSize='2cqw'
                iconSx={{width: '10cqw', height: 'auto'}}
              ></VerticalTicketItem>
            </FadeInAnimation>
          </Box>
        </Section>
        <Section id='home-4' elevation={1} height={40} sx={{backgroundColor: theme.palette.primary.main, maxHeight: '20vw', borderRadius: '0', zIndex: 5}}>
          <Box id='home-4-container'position='relative' width='100%' height='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='center' zIndex={0}>
            <Box id='background-svg-container' position={'absolute'} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%', height: '100%', zIndex: 1, overflow: 'hidden'}}>
              <img width='100%' src={terralinesSvg} alt="background svg" />
            </Box>
            <Box id='content-container' position={'absolute'} sx={{ display:'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', justifyContent:'space-around', width: '100%', height: '100%', zIndex: 2}}>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>140</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>MEGAWATTS DEPLOYED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>~7EH</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>HASHRATE OPERATED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>7</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>SITES MANAGED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>40</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>TEAM MEMBERS</Typography>
                </Box>
            </Box>
          </Box>
        </Section>
        <Section id='home-5' elevation={0} height={200} sx={{padding: '10cqw'}}>
          <Stack width='100%' direction='column' alignItems='center' justifyContent='space-evenly' spacing={2}>
            <FadeInAnimation delay={.2}>
              <Paper sx={{minWidth: '80%',  border: '.1rem solid #E6E6E6', padding: '1rem 0 0 1rem', backgroundColor: '#F5F5F5', borderRadius: '1.2rem'}} elevation={0} >
                  <Stack direction='column'>
                    <Stack direction='row' alignItems='flex-end' sx={{backgroundColor: '#F4DFC2', width: '62%', padding: '1cqw', borderRadius: '.5rem'}}>
                      <Typography variant='h5' sx={{width: '70%'}}>Jay Zapata,</Typography><Typography variant='h6' textAlign='right' alignItems='flex-end' sx={{width: '20%'}}>CEO</Typography>
                    </Stack>
                    <br></br>
                    <Typography variant='subtitle1' sx={{margin: '0 1rem 1rem 0'}}>
                      With over a decade in financial leadership and operational management, Jay has a proven track record of implementing robust financial controls & driving efficiency across sectors. He has held roles such as Optimization Director & Financial Controller for private equity-backed companies, focusing on financial strategy & performance. Before joining Satokie, Jay served as CFO of a $150MM revenue organization, managing complex portfolios and ensuring regulatory compliance.
                    </Typography>
                    <Stack direction='row' justifyContent='flex-end' sx={{width: '100%', margin: 0, borderRadius: '15%'}}>
                      <img style={{objectFit: 'contain', width: '50%', borderRadius: '1.2rem 0 1.2rem 0'}} src={jayHeadshot} alt="Very Handsome Headshot of Jay Zapata" />
                    </Stack>
                  </Stack>
              </Paper>
            </FadeInAnimation>
            <FadeInAnimation delay={.2}>
              <Paper sx={{minWidth: '80%', border: '.1rem solid #E6E6E6', padding: '1rem 0 0 1rem', backgroundColor: '#F5F5F5', borderRadius: '1.2rem'}} elevation={0} >
                  <Stack direction='column'>
                    <Stack direction='row' alignItems='flex-end' sx={{backgroundColor: '#FDD1AD', width: '72%', padding: '1cqw', borderRadius: '.5rem'}}>
                      <Typography variant='h5' sx={{width: '70%'}}>Shyke Lowers,</Typography><Typography variant='h6' textAlign='right' alignItems='flex-end' sx={{width: '20%'}}>COO</Typography>
                    </Stack>
                    <br></br>
                    <Typography variant='subtitle1' sx={{margin: '0 1rem 1rem 0'}}>
                      Shyke brings extensive experience to his role as COO at Satokie Mining. A former Power Trader with vast knowledge in power markets and asset optimization from managing a 120+MW battery portfolio in the ERCOT Market, he now leads Demand Response & Power Procurement strategies, focusing on strategic execution & scaling Satokie’s operations.
                      <br></br>
                      <br></br>
                      <br></br>
                    </Typography>
                    <Stack direction='row' justifyContent='flex-end' sx={{width: '100%', margin: 0, borderRadius: '15%'}}>
                      <img style={{objectFit: 'contain', width: '50%', borderRadius: '1.2rem 0 1.2rem 0'}} src={shykeHeadshot} alt="Very Handsome Headshot of Shyke Lowers" />
                    </Stack>
                  </Stack>
              </Paper>
            </FadeInAnimation>
            <FadeInAnimation delay={.2}>
              <Paper sx={{minWidth: '80%',  border: '.1rem solid #E6E6E6', padding: '1rem 0 0 1rem', backgroundColor: '#F5F5F5', borderRadius: '1.2rem'}} elevation={0} >
                  <Stack direction='column'>
                    <Stack direction='row' alignItems='flex-end' sx={{backgroundColor: '#C5A391', width: '98%', padding: '1cqw', borderRadius: '.5rem'}}>
                      <Typography variant='h5' sx={{width: '50%'}}>Omar Alatorre,</Typography><Typography variant='h6' textAlign='right' alignItems='flex-end' sx={{width: '50%', fontSize: '4cqw'}}>Director of Site Ops</Typography>
                    </Stack>
                    <br></br>
                    <Typography variant='subtitle1' sx={{margin: '0 1rem 1rem 0'}}>
                      Omar has a demonstrated history of effectively overseeing large projects & guiding cross-functional teams to enhance operational efficiency. He gained extensive experience in operations & management by deploying preventive maintenance & technician operation teams for a 300+ MW Wind Farm portfolio, which is backed by one of the country’s major capital infrastructure investors.
                      <br></br>
                      <br></br>
                      <br></br>                    
                    </Typography>
                    <Stack direction='row' justifyContent='flex-end' sx={{width: '100%', margin: 0, borderRadius: '15%'}}>
                      <img style={{objectFit: 'contain', width: '50%', borderRadius: '1.2rem 0 1.2rem 0'}} src={omarHeadshot} alt="Very Handsome Headshot of Omar Alatorre" />
                    </Stack>
                  </Stack>
              </Paper>
            </FadeInAnimation>
          </Stack>
        </Section>
        <Section id='home-6' elevation={0} height={150} sx={{backgroundColor: theme.palette.secondary.main, maxHeight: '160vw', borderRadius: '0', zIndex: 0, paddingBottom: '2cqw'}}>
          <Box id='gallery-container' width={'100%'} height={'100%'} sx={{padding: '6cqw', zIndex: 1}}>
            <Grid container id='gallery-parent-grid' spacing={3} width={'100%'} height={'100%'} sx={{zIndex: 2}}>
                <Grid id='gallery-left-column'size={6}>
                  <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                    <Grid id='gallery-left-column-container' container rowSpacing={3} width={'100%'} height={'100%'}>
                      <Grid id='top-left-text' size={12} sx={{height: '30%', width:'100%', zIndex: 3}}>
                        <Box id='top-left-text-box'>
                          <Box id='top-left-text-title'>
                            <FadeInAnimation delay ={0.3}>
                              <Typography variant='h3' sx={{fontWeight: 'bold', fontSize: '4cqw', color: theme.palette.primary.main}}>Setting the Standard for Bitcoin Mining Colocation</Typography>
                            </FadeInAnimation>
                          </Box>
                          <Box id='top-left-text-content' sx={{width: '90%'}}>
                              <FadeInUpAnimation delay={0.5}>
                                <Typography variant='h5' sx={{lineHeight: '3.5cqw', fontSize: '2.5cqw'}}>Our mission is to deliver cutting-edge solutions that optimize energy assets by leveraging Bitcoin Mining as a tool. Satokie Mining aims to embed itself at the intersection of Bitcoin mining and traditional energy markets and assets.</Typography>
                              </FadeInUpAnimation>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid id='bottom-left-img' size={12} sx={{height: '70%', width: '100%', zIndex: 3}}>
                        <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                          <FadeInAnimation delay={0.4} sx={{width: '100%', height: '100%'}}>
                            <img src={racksImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Racks Image" />
                          </FadeInAnimation>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid id='gallery-right-column' size={6} sx={{height: '100%'}}>
                <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                  <Grid id='gallery-left-column-container' container rowSpacing={3} width={'100%'} height={'100%'}>
                    <Grid id='top-right-img' size={12} sx={{ height: '30%', width:'100%', zIndex: 3}}>
                      <Box id='bottom-left-img-container' sx={{ height: '100%', width: '100%', zIndex: 4}}>
                        <FadeInAnimation delay={0.3} sx={{width: '100%', height: '100%'}}>
                          <img src={teamImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Team Image" />
                        </FadeInAnimation>
                      </Box>
                    </Grid>
                    <Grid id='bottom-right-img' size={12} sx={{ height: '70%', width: '100%', zIndex: 3}}>
                      <Box sx={{height: '100%', width: '100%'}}>
                        <Grid container spacing={3} id='bottom-right-img-flex-container' sx={{width: '100%', height: '100%',}}>
                            <Grid size={12} sx={{width: '100%', height: '47%' }}>
                              <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                              <FadeInAnimation delay={0.5} sx={{width: '100%', height: '100%'}}>
                                <img src={teamInspectionImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Techs Image" />
                              </FadeInAnimation>
                              </Box>
                            </Grid>
                            <Grid size={12} sx={{width: '100%', height: '47%'}}>
                              <Box id='bottom-right-img-2' sx={{width: '100%', height: '100%'}}>
                              <FadeInAnimation delay={0.7} sx={{width: '100%', height: '100%'}}>
                                <img src={siteRowsImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Site Rows Image" />
                              </FadeInAnimation>
                              </Box>
                            </Grid>                        
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Footer isMobile={isMobile}/>
      </Paper>
    :
    //Desktop
      <Paper  sx={{position: 'relative', flexGrow: 1, overflowX: 'hidden', borderRadius: '0'}}>
        <Nav activeRoute={activeRoute} isMobile={isMobile}/>
        <Section id='hero' elevation={0} height={60} sx={{backgroundColor: theme.palette.primary.main}}>
          <Grid container spacing={3} sx={{display: 'flex', justifyContent: 'center', alignItems:'center', height: "100%"}}>
            <Grid size={5} sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'end'}}>
              <Typography variant='h3' sx={{fontSize: 'calc(2vh + 2vw)', textWrap: 'wrap', fontWeight: 'bold', marginBottom: '4cqw'}} color={theme.palette.secondary.main}>We build, own & operate data centers to support the bitcoin network</Typography>
              <Button
                variant='contained'
                color='secondary'
                sx={{marginBottom: '2cqw'}}
                onClick={() => document.querySelector('#footer-contact')?.scrollIntoView({ behavior: 'smooth' })}>
                Partner with us now
              </Button>
            </Grid>
            <Grid size={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', height: '100%'}}>
                <VideoItem sx={{
                  width: '90%',
                  height: '80%',
                  boxShadow: '0px 16px 16px rgba(0,0,0, .5)',
                  objectFit: 'cover',
                  borderRadius: '1%',
                  marginBottom: '2cqw'
                }} videoUrl={videoUrl} isMobile={isMobile} typeString={'video/mp4'} />
            </Grid>
          </Grid>
        </Section>
        <Section id='services' elevation={0} height={100} sx={{backgroundColor: theme.palette.secondary.main, maxHeight: '45vw', position: 'relative', zIndex: 0 }}>
          <Box id='grid-box'  sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <FadeInUpAnimation sx={{height: '100%'}}>
              <Grid  sx={{ width: '90cqw', height: '100%'}} id='ticket-container' wrap='nowrap' container spacing={2}>
                <Grid id='ticket-1' size={3} position='relative'>
                  <VerticalTicketItem 
                    theme={theme}
                    icon={FactoryIcon}
                    contentTitle='Site Operations'
                    content='TBD'
                    titleFontSize='1.3cqw'
                    contentFontSize='.8cqw'
                    iconSx={{width: '3cqw', height: 'auto'}}
                  ></VerticalTicketItem>
                </Grid>
                <Grid id='ticket-2' size={3} position='relative'>
                  <VerticalTicketItem
                    theme={theme}
                    icon={ConstructionIcon}
                    contentTitle="Site Acquisition & Development"
                    content='TBD'
                    titleFontSize='1.3cqw'
                    contentFontSize='.8cqw'
                    iconSx={{width: '3cqw', height: 'auto'}}
                  ></VerticalTicketItem>
                </Grid>
                <Grid id='ticket-3' size={3} position='relative'>
                  <VerticalTicketItem
                    theme={theme}
                    icon={BuildIcon}
                    contentTitle='ASIC Repair'
                    content='TBD'
                    titleFontSize='1.3cqw'
                    contentFontSize='.8cqw'
                    iconSx={{width: '3cqw', height: 'auto'}}
                  ></VerticalTicketItem>
                </Grid>
                <Grid id='ticket-4' size={3} position='relative'>
                  <VerticalTicketItem
                    theme={theme}
                    icon={ElectricBoltIcon}
                    contentTitle='Energy Consultation'
                    content='TBD'
                    titleFontSize='1.3cqw'
                    contentFontSize='.8cqw'
                    iconSx={{width: '3cqw', height: 'auto'}}
                  ></VerticalTicketItem>

                </Grid>
              </Grid>
            </FadeInUpAnimation>
          </Box>
        </Section>
        <Section id='home-4' elevation={0} height={40} sx={{backgroundColor: theme.palette.primary.main, maxHeight: '50vw'}}>
          <Box id='home-4-container'position='relative' width='100%' height='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='center' zIndex={0}>
            <Box id='background-svg-container' position={'absolute'} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%', height: '100%', zIndex: 1, overflow: 'hidden'}}>
              <img width='100%' style={{aspectRatio: '1/1'}} src={terralinesSvg} alt="background svg" />
            </Box>
            <Box id='content-container' position={'absolute'} sx={{ display:'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', justifyContent:'space-around', width: '100%', height: '100%', zIndex: 2}}>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>140</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>MEGAWATTS DEPLOYED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>~7EH</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>HASHRATE OPERATED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>7</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>SITES MANAGED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>40</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>TEAM MEMBERS</Typography>
                </Box>
            </Box>
          </Box>
        </Section>
        <Section id='home-5' elevation={0} height={80} sx={{padding: '2%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <Stack width='100%' height='100%' direction='row' alignItems='center' justifyContent='center' spacing={5}>
              <Card sx={{ width: '20%', height:'auto', border: '.1rem solid #E6E6E6', padding: '1rem 0 0 1rem', backgroundColor: '#F5F5F5', borderRadius: '1.2rem'}}>
                  <Stack direction='column' alignItems='space-evenly'>
                    <Stack direction='row' justifyContent='flex-start' alignItems='center' sx={{height: '10%', backgroundColor: '#F4DFC2', width: '58%', padding: '.5cqw', borderRadius: '.5cqw'}}>
                      <Typography variant='h5' sx={{marginRight: '.5rem', fontSize: '1.0cqw'}}>Jay Zapata,</Typography><Typography variant='h6' textAlign='left' alignItems='flex-end' sx={{fontSize: '.8cqw'}}>CEO</Typography>
                    </Stack>
                    <br></br>
                    <Typography variant='subtitle1' sx={{margin: '0 1rem 1rem 0', height: '80%', fontSize: '.8cqw'}}>
                      With over a decade in financial leadership and operational management, Jay has a proven track record of implementing robust financial controls & driving efficiency across sectors. He has held roles such as Optimization Director & Financial Controller for private equity-backed companies, focusing on financial strategy & performance. Before joining Satokie, Jay served as CFO of a $150MM revenue organization, managing complex portfolios and ensuring regulatory compliance.
                    </Typography>
                    <Stack direction='row' justifyContent='flex-end' sx={{width: '100%', margin: 0, borderRadius: '15%', height: '10%'}}>
                      <img style={{objectFit: 'contain', width: '50%', borderRadius: '1.2rem 0 1.2rem 0'}} src={jayHeadshot} alt="Very Handsome Headshot of Jay Zapata" />
                    </Stack>
                  </Stack>
              </Card>
              <Card sx={{ width: '20%', height: 'auto', border: '.1rem solid #E6E6E6', padding: '1rem 0 0 1rem', backgroundColor: '#F5F5F5', borderRadius: '1.2rem'}}>
                <Stack direction='column' alignItems='space-evenly'>
                    <Stack direction='row' justifyContent='flex-start' alignItems='center' sx={{height: '10%', backgroundColor: '#FDD1AD', width: '58%', padding: '.5cqw', borderRadius: '.5cqw'}}>
                      <Typography variant='h5' sx={{marginRight: '.5rem', fontSize: '1.0cqw'}}>Shyke Lowers,</Typography><Typography variant='h6' textAlign='left' alignItems='flex-end' sx={{fontSize: '.8cqw'}}>COO</Typography>
                    </Stack>
                    <br></br>
                    <Typography variant='subtitle1' sx={{margin: '0 1rem 1rem 0', height: '80%', fontSize: '.8cqw'}}>
                      Shyke brings extensive experience to his role as COO at Satokie Mining. A former Power Trader with vast knowledge in power markets and asset optimization from managing a 120+MW battery portfolio in the ERCOT Market, he now leads Demand Response & Power Procurement strategies, focusing on strategic execution & scaling Satokie’s operations.
                      <br />
                      <br />
                      <br />
                      <br />
                    </Typography>
                    <Stack direction='row' justifyContent='flex-end' sx={{width: '100%', margin: 0, borderRadius: '15%', height: '10%'}}>
                      <img style={{objectFit: 'contain', width: '50%', borderRadius: '1.2rem 0 1.2rem 0'}} src={shykeHeadshot} alt="Very Handsome Headshot of Shyke Lowers" />
                    </Stack>
                  </Stack>
              </Card>
              <Card sx={{width:'20%',  height: 'auto',  border: '.1rem solid #E6E6E6', padding: '1rem 0 0 1rem', backgroundColor: '#F5F5F5', borderRadius: '1.2rem'}} elevation={0} >
                  <Stack direction='column' alignItems='space-evenly'>
                    <Stack direction='row' justifyContent='flex-start' alignItems='center' sx={{height: '10%', backgroundColor: '#C5A391', width: '88%', padding: '.5cqw', borderRadius: '.5cqw'}}>
                      <Typography variant='h5' sx={{marginRight: '.5rem', fontSize: '1.0cqw'}}>Omar Alatorre,</Typography><Typography variant='h6' textAlign='right' alignItems='flex-end' sx={{fontSize: '.8cqw'}}>Director of Site Ops</Typography>
                    </Stack>
                    <br></br>
                    <Typography variant='subtitle1' sx={{margin: '0 1rem 1rem 0', height: '80%', fontSize: '.8cqw'}}>
                      Omar has a demonstrated history of effectively overseeing large projects & guiding cross-functional teams to enhance operational efficiency. He gained extensive experience in operations & management by deploying preventive maintenance & technician operation teams for a 300+ MW Wind Farm portfolio, which is backed by one of the country’s major capital infrastructure investors.
                      <br></br>
                      <br></br>         
                      <br></br>         
                    </Typography>
                    <Stack direction='row' justifyContent='flex-end' sx={{width: '100%', height: '10%', margin: 0, borderRadius: '15%'}}>
                      <img style={{objectFit: 'contain', width: '50%', borderRadius: '1.2rem 0 1.2rem 0'}} src={omarHeadshot} alt="Very Handsome Headshot of Omar Alatorre" />
                    </Stack>
                  </Stack>
              </Card>
          </Stack>
        </Section>
        <Section id='home-6' elevation={0} height={150} sx={{backgroundColor: theme.palette.secondary.main, maxHeight: '160vw', zIndex: 0, marginBottom: '8cqw'}}>
          <Box id='gallery-container' width={'100%'} height={'100%'} sx={{padding: '6cqw', zIndex: 1}}>
            <Grid container id='gallery-parent-grid' spacing={6} width={'100%'} height={'100%'} sx={{zIndex: 2}}>
                <Grid id='gallery-left-column'size={6}>
                  <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                    <Grid id='gallery-left-column-container' container rowSpacing={7.5} width={'100%'} height={'100%'}>
                      <Grid id='top-left-text' size={12} sx={{height: '30%', width:'100%', zIndex: 3}}>
                        <Box id='top-left-text-box'>
                          <Box id='top-left-text-title' sx={{marginBottom: '2cqh'}}>
                            <FadeInUpAnimation delay={0}>
                              <Typography variant='h3' sx={{fontWeight: 'bold', fontSize: '3cqw', color: theme.palette.primary.main}}>Setting the Standard for Bitcoin Mining Colocation</Typography>
                            </FadeInUpAnimation>
                          </Box>
                          <Box id='top-left-text-content' sx={{width: '90%'}}>
                            <FadeInUpAnimation delay={0.2}>
                              <Typography variant='h5' sx={{lineHeight: '4cqh', fontSize: '1.6cqw'}}>Our mission is to deliver cutting-edge solutions that optimize energy assets by leveraging Bitcoin Mining as a tool. Satokie Mining aims to embed itself at the intersection of Bitcoin mining and traditional energy markets and assets.</Typography>
                            </FadeInUpAnimation>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid id='bottom-left-img' size={12} sx={{height: '70%', width: '100%', zIndex: 3}}>
                        <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                          <FadeInAnimation delay={0.4} sx={{height: '100%', width: '100%'}}>
                            <img src={racksImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '3/2'}}  alt="Satokie Racks Image" />
                          </FadeInAnimation>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid id='gallery-right-column' size={6} sx={{height: '100%'}}>
                <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                  <Grid id='gallery-left-column-container' container rowSpacing={7.5} width={'100%'} height={'100%'}>
                    <Grid id='top-right-img' size={12} sx={{ height: '30%', width:'100%', zIndex: 3}}>
                        <Box id='bottom-left-img-container' sx={{ height: '100%', width: '100%', zIndex: 4}}>
                          <FadeInAnimation delay={0.3} sx={{ height: '100%', width: '100%'}}>
                            <img src={teamImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '1/3'}}  alt="Satokie Team Image" />
                          </FadeInAnimation>
                        </Box>
                    </Grid>
                    <Grid id='bottom-right-img' size={12} sx={{ height: '70%', width: '100%', zIndex: 3}}>
                      <Box sx={{height: '100%', width: '100%'}}>
                        <Grid container spacing={6} id='bottom-right-img-flex-container' sx={{width: '100%', height: '100%',}}>
                            <Grid size={12} sx={{width: '100%', height: '47%' }}>
                              <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                                <FadeInAnimation delay={0.3} sx={{width: '100%', height: '100%'}}>
                                  <img src={teamInspectionImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '4/1'}}  alt="Satokie Techs Image" />
                                </FadeInAnimation>
                              </Box>
                            </Grid>
                            <Grid size={12} sx={{width: '100%', height: '47%'}}>
                              <Box id='bottom-right-img-2' sx={{width: '100%', height: '100%'}}>
                                <FadeInAnimation delay={0.3} sx={{width: '100%', height: '100%'}}>
                                  <img src={siteRowsImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '1/3'}}  alt="Satokie Site Rows Image" />
                                </FadeInAnimation>
                              </Box>
                            </Grid>                        
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Footer isMobile={isMobile}/>
      </Paper>
    }
    </React.Fragment>
  );
}
