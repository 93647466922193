import { ContactFormData } from "../model/contact/ContactFormData";
import { ContactFormToDbRow } from "../model/contact/FormDataToContactDbRowClass";


export const submitContactForm = async (formData: ContactFormData) => {
  const proxyUrl= '/.netlify/functions/proxy';
  const notionUrl= 'https://api.notion.com/v1/pages'
  
  //payload
  const converter = new ContactFormToDbRow(formData);
  
  //headers
  const fetchHeaders = {
    'Content-Type': 'application/json',
    'Notion-Version': '2022-02-22',
  }
  //options
  let response;
  //fetch 
  try{
    console.log(converter.getJson());
    response = await fetch(`${proxyUrl}?url=${notionUrl}`, {
      method: 'POST',
      headers: fetchHeaders,
      body: JSON.stringify(converter.getJson())
    });
    console.log(response.data)
    return response
  }catch( e: any){
    console.log(`Error on Form Submission: ${e.message}`);
    return response
  }
}