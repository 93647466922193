import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FadeInUpAnimation from '../components/FadeInUp';

export default function AboutCard({imgSrc, cardText, imgAlt}): React.JSX.Element {
  return (
    <FadeInUpAnimation>
      <Card sx={{ display: 'flex', padding: 2, alignItems: 'center' }}>
      {/* Image Section */}
      <CardMedia
        component="img"
        image={imgSrc}
        alt={imgAlt}
        sx={{
          width: '35%',
        }}
      />

      {/* Spacer */}
      <Box sx={{ width: '5%' }} />

      {/* Text Section */}
      <CardContent sx={{ width: '60%', padding: '0 !important' }}>
        <Typography variant="h6" sx={{fontSize: 'calc(.7vh + .8vw)'}}>
          {cardText}
        </Typography>
      </CardContent>
    </Card>
    </FadeInUpAnimation>
  );
};