import React from 'react';
import { Grid2 as Grid, Link } from "@mui/material";
import logo from '../assets/images/logo-1.png';

export default function Logo({isMobile}): React.JSX.Element{
  
  return (
    <Grid sx={{ zIndex: 3}}>
      <Link href='#hero' onClick={(e) => {
        e.preventDefault()
        const target = document.querySelector('#hero');
        target?.scrollIntoView({behavior: 'smooth'})
      }}>
        <img src={logo} style={{
          width: '7.5cqw',
          height: '6cqw',
          margin: 'calc(2vw + 2vh)',
          position: 'absolute',
          filter: 'drop-shadow(0em 0em 5px #000)'
        }} />
      </Link>
    </Grid>
  )
}