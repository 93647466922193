import { ContactDbRow } from "./ContactDbRow";
import { ContactFormData } from "./ContactFormData";

export class ContactFormToDbRow {
  private contactDbRow: ContactDbRow;

  constructor(formData: ContactFormData) {
      // Initialize the `ContactDbRow` object
      this.contactDbRow = {
          parent: {
              database_id: "fff868875213815d8195c39a25085797", // Default value
          },
          properties: {
              Contact: {
                  title: [
                      {
                          text: {
                              content: formData.fullName,
                          },
                      },
                  ],
              },
              Company: {
                  rich_text: [
                      {
                          text: {
                              content: formData.companyName,
                          },
                      },
                  ],
              },
              Status: {
                  status: {
                      name: "Lead", // Default status
                  },
              },
              Email: {
                  email: formData.email,
              },
              Phone: {
                  phone_number: formData.phoneNumber,
              },
              Industry: {
                  rich_text: [
                      {
                          text: {
                              content: formData.jobTitle,
                          },
                      },
                  ],
              },
              Message: {
                  rich_text: [
                      {
                          text: {
                              content: formData.message,
                          },
                      },
                  ],
              },
              "Company Size": {
                  select: {
                      name: formData.companySize,
                  },
              },
              "Primary Interest": {
                  select: {
                      name: formData.primaryInterest,
                  },
              },
          },
      };
  }

  // Getter to retrieve the JSON object
  public getJson(): ContactDbRow {
      return this.contactDbRow;
  }
}