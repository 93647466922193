import React, { useState } from 'react';
import InputMask from 'react-input-mask'
import {Box, Link, Typography, useTheme, TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid2 as Grid, FormHelperText, responsiveFontSizes} from '@mui/material';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Section from './Section';
import poloImg from '../assets/images/polo.jpg';
import terralinesSvg from '../assets/images/terralines.svg';
import { submitContactForm } from '../services/contact';

interface FProps {
  isMobile: boolean
}

export default function Footer({isMobile}: FProps): React.JSX.Element {
  const theme = useTheme();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
    companySize: '',
    primaryInterest: '',
    companyName: '',
    message: '',
  })

  // placeholders to show errors if needed
  const [helperText, setHelperText] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
    companySize: '',
    primaryInterest: '',
    companyName: '',
  })

  // Fill formData when the form is changed + validate name, job title, company name
  const handleChange = (e) => {
    const { name, value } = e.target
    // Clear any previous errors
    setHelperText({ ...helperText, [name]: '' })
    // Show helpertext and highlight red if empty
    if (['fullName', 'jobTitle', 'companyName'].includes(name)) {
      if (value.trim() === "") setHelperText({ ...helperText, [name]: 'Required' })
    }
    setFormData({...formData, [name]: value})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const errors = new Object // if still empty at the end it will submit
    // Check if any required fields are empty
    for (const field of ['fullName', 'jobTitle', 'companyName', 'companySize', 'primaryInterest']) {
      if (formData[field].trim() === "") errors[field] = 'Required'
    }
    // Verify 10 digits were entered
    if (formData.phoneNumber.trim().length !== 14) errors["phoneNumber"] = 'Please enter a valid number'
    // Verify the email is valid
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) errors['email'] = 'Please enter a valid email'

    if (Object.keys(errors).length) {
      // Show errors and exit out
      setHelperText({...helperText, ...errors})
      return
    }
    try{
      let response = await submitContactForm(formData);
      if (response.status === 200) console.log("Success!")
      else console.error(response.status + response.message)
    }catch(e:any){
      console.error('Contact form submission error!: ' + e.message)
      // errors['global'] = 'Form failed to submit! Please try again later.'
    }
  }
  
  return (
    <React.Fragment> 
      <Section id='footer-contact' elevation={0} height={isMobile ? 20 : 80} sx={{overflow: 'hidden'}}>
        <Box id='footer-contact-container' sx={{ width: '80%', height: '80%', position: 'relative', margin: '0 10%' }}>
          <Typography variant='h2' marginBottom={'2cqw'}>Contact Us!</Typography>
          <Grid container spacing={4} component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid size={3}>
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                required
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                helperText={helperText.fullName || ' '} // Empty space to prevent form spacing from changing
                error={!!helperText.fullName} // Sets to true when there is a helpertext
              />

              <TextField
                label="Email"
                variant="outlined"
                type="email"
                fullWidth
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
                helperText={helperText.email || ' '}
                error={!!helperText.email}
              />

              <InputMask
                mask="(999) 999-9999"
                maskChar=" "
                value={formData.phoneNumber}
                onChange={handleChange}>
                {(inputProps) => 
                  <TextField
                    {...inputProps}
                    label="Phone Number"
                    variant="outlined"
                    type="tel"
                    fullWidth
                    name="phoneNumber"
                    helperText={helperText.phoneNumber || ' '}
                    error={!!helperText.phoneNumber}
                    slotProps={{input: {inputMode: 'numeric'}}}
                  />}
              </InputMask>
            </Grid>

            <Grid size={3}>
              <TextField
                label="Job Title"
                variant="outlined"
                fullWidth
                required
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                helperText={helperText.jobTitle || ' '}
                error={!!helperText.jobTitle}
              />

              <FormControl fullWidth error={!!helperText.companySize}>
                <InputLabel>Company Size</InputLabel>
                <Select
                  label="Company Size"
                  required
                  name="companySize"
                  value={formData.companySize}
                  onChange={handleChange}>
                  <MenuItem value="1-10">1-10</MenuItem>
                  <MenuItem value="10-100">10-100</MenuItem>
                  <MenuItem value="100-1000">100-1000</MenuItem>
                  <MenuItem value="1000+">1000+</MenuItem>
                </Select>
                <FormHelperText>{helperText.companySize || ' '}</FormHelperText>
              </FormControl>

              <FormControl fullWidth error={!!helperText.primaryInterest}>
                <InputLabel>Primary Interest</InputLabel>
                <Select
                  label="Primary Interest"
                  required
                  name="primaryInterest"
                  value={formData.primaryInterest}
                  onChange={handleChange}>
                  <MenuItem value="Energy Consultation">Energy Consultation</MenuItem>
                  <MenuItem value="ASIC Repair">ASIC Repair</MenuItem>
                  <MenuItem value="Site Acquisition & Development">Site Acquisition & Development</MenuItem>
                  <MenuItem value="Site Operations">Site Operations</MenuItem>
                </Select>
                <FormHelperText>{helperText.primaryInterest || ' '}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid size={6}>
              <TextField
                label="Company Name"
                variant="outlined"
                fullWidth
                required
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                helperText={helperText.companyName || ' '}
                error={!!helperText.companyName}
              />

              <TextField
                label="Optional Message"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>

            <Button variant="contained" color="primary" type='submit'>
              Submit
            </Button>
          </Grid>
      </Box>
      <Box id='subfooter-container' sx={{height: '20%', width: '100%', position: 'relative', backgroundColor: theme.palette.primary.main}}>
        <Box id='subfooter-background-img' sx={{height: '100%', width: '100%', position: 'absolute'}}>
            <img src={terralinesSvg} style={{width: '100%', height: '200%', objectFit: 'cover', aspectRatio: '16/9'}} alt="terralines background svg" />
        </Box>
        <Box id='subfooter-overlay' sx={{height: '100%', width: '90%', marginLeft: '3cqw', position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <Box sx={{textAlign: 'center'}}>
            <Link href="mailto:contact@satokiemining.io">
              <Typography variant='h6' sx={{ fontSize: '1.5cqw', color: theme.palette.secondary.main, textShadow: '0em .4em .5em rgba(0,0,0, .8) !important', textDecoration: 'underline'}}>contact@satokiemining.io</Typography>
            </Link>
          </Box>
          <Box>
            <Link href="https://x.com/SatokieMining">
              <XIcon sx={{ fontSize: '1.9cqw', marginTop: 'calc(.65vw + .65vh)', marginRight: '.5cqw', marginLeft: '1.5rem', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}}/>
            </Link>
            <Link href="https://www.linkedin.com/company/satokie-mining">
              <LinkedInIcon sx={{ fontSize: '2cqw', marginTop: '1cqh', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}} />
            </Link> 
          </Box>
        </Box>
      </Box>
    </Section>
  </React.Fragment>
)
}